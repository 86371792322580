import { Redirect } from '@reach/router'
import { graphql } from 'gatsby'
import React from 'react'

import { NpmClubCardUserPage } from '../../local_modules'

import type { ClubCardUserPageSectionData } from '../../local_modules/types/ClubCard/ClubCardUser'
import type { PageProps } from 'gatsby'

interface ClubCardUserPageSection {
  cmsClubCardUserLp?: {
    sections?: [{ data?: ClubCardUserPageSectionData }]
  }
}

export default function ClubCardUserPage({
  data,
  location: { pathname },
}: PageProps<ClubCardUserPageSection>) {
  const clubCardUserPageData = data?.cmsClubCardUserLp?.sections?.[0]?.data

  if (!clubCardUserPageData || !clubCardUserPageData.visibility) {
    const params = new URLSearchParams({ from: encodeURIComponent(pathname) })

    return <Redirect to={`/404?${params.toString()}`} noThrow />
  }

  return <NpmClubCardUserPage data={clubCardUserPageData} />
}

export const querySSG = graphql`
  query ClubCardUserPageQuery {
    cmsClubCardUserLp {
      sections {
        data
      }
    }
  }
`
